import { registerPlugin, type PluginListenerHandle } from '@capacitor/core'

interface UploadFileOptions {
  filePath: string
  thumbnail: string
  token: string
  url: string
  assetType?: string
  attemptId?: string
}

interface UploadProfilePictureOptions {
  file: string // base64 of the image
  token: string
  url: string
  assetType: string
  attemptId?: string
}

interface FileUploadPluginAndroid {
  uploadFile(options: UploadFileOptions): Promise<void>
  uploadProfilePicture(options: UploadProfilePictureOptions): Promise<void>
  addListener(eventName: string, listenerFunc: (info: { progress: number }) => void): Promise<PluginListenerHandle>
}

const FileUpload = registerPlugin<FileUploadPluginAndroid>('FileUploadPluginAndroid')

export default FileUpload