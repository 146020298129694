import './assets/main.css'
import '@/assets/fonts/fonts.css'
import 'vue-final-modal/style.css'
import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
import './firebase'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import { createVfm } from 'vue-final-modal'
import VueApexCharts from 'vue3-apexcharts'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import App from './App.vue'
import { router } from './router'
import { useAuthUserStore } from './stores/auth-store'

const pinia = createPinia()
const app = createApp(App)
const vfm = createVfm()

app.use(IonicVue)
app.use(pinia) // Make sure pinia is used before accessing any stores
app.use(VueApexCharts)
app.use(vfm)

const authStore = useAuthUserStore()
authStore.setRouter(router)

// Initialize auth before mounting the app
app.use(router)
router.isReady().then(() => {
  app.mount('#app')
})
