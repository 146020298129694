import * as httpClient from './capacitor-http-client'
import type { User } from '@/types/User'

export type Credentials = {
  id?: string
  name: string
  email: string
  password: string
  acknowledged_terms_and_conditions: boolean
  acknowledged_privacy_policy: boolean
}

const login = (payload: Credentials) => httpClient.post<User>('/auth/login', payload).then((response) => response.data)

const signup = (credentials: Pick<Credentials, 'id' | 'name' | 'acknowledged_terms_and_conditions'>) =>
  httpClient.post<User>('/auth/signup', credentials).then((response) => response.data)

const logout = () => httpClient.post('/auth/logout')

const verifyPin = (pin: string) => httpClient.post('/auth/verify', { pin })

const resendPin = () => httpClient.get('/auth/verify/resend')

const authService = { login, signup, logout, verifyPin, resendPin }

export default authService
