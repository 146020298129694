import { defineStore } from 'pinia'
import { reactive, toRefs } from 'vue'
import { useLoadingStore } from './loading-store'
import { useToastStore } from './toast-store'
import profileService from '../services/profile-service'
import type { UserProfile } from '@/types/UserProfile'
import fileService from '@/services/file-service'
import userService from '@/services/user-service'

export const useProfileStore = defineStore('profile-store', () => {
  const loading = useLoadingStore()
  const toast = useToastStore()

  const getInitialState = () => ({
    profile: undefined as UserProfile | undefined,
  })

  const state = reactive(getInitialState())

  const show = async () => {
    loading.toggleLoading('profile-show-loading')
    try {
      const response = await profileService.show()
      state.profile = response.data
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('profile-show-loading')
    }
  }

  const store = async (payload: Partial<UserProfile>) => {
    loading.toggleLoading('profile-store-loading')
    try {
      const response = await profileService.store(payload)
      state.profile = response.data
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('profile-store-loading')
    }
  }

  const update = async (payload: Partial<UserProfile>) => {
    loading.toggleLoading('profile-update-loading')
    try {
      const response = await profileService.update(payload)
      await userService.me()
      state.profile = response.data
      toast.displaySuccess(response)
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('profile-update-loading')
    }
  }

  const updateProfilePicture = async (file: File) => {
    loading.toggleLoading('profile-update-loading')
    try {
      const response = await fileService.storeProfilePicture(file, 'profile-picture')
      if (response) {
        state.profile = response.data
      }
      toast.displaySuccess(response?.data)
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('profile-update-loading')
    }
  }

  const reset = () => {
    Object.assign(state, getInitialState())
  }

  return {
    ...toRefs(state),
    show,
    store,
    update,
    updateProfilePicture,
    reset,
  }
})
