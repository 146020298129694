import { defineStore } from 'pinia'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { computed, reactive, ref, toRefs } from 'vue'
import { useLoadingStore } from './loading-store'
import { useToastStore } from './toast-store'
import type { Router } from 'vue-router'
import authService, { type Credentials } from '@/services/auth-service'
import userService from '@/services/user-service'
import type { User } from '@/types/User'
import { useProfileStore } from './profile-store'

export const useAuthUserStore = defineStore('auth-store', () => {
  const loading = useLoadingStore()
  const toast = useToastStore()
  const profileStore = useProfileStore()
  let routerInstance: Router | null = null
  const isInitialized = ref(false)

  const state = reactive({
    authUser: undefined as User | null | undefined,
    isAuthenticated: false,
  })

  const firebaseUser = ref<any | null>(null)

  const profileUsername = computed(() => state.authUser?.first_name || '')

  const setRouter = (router: Router) => {
    routerInstance = router
  }

  const login = async (email: string, password: string) => {
    try {
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      })
      firebaseUser.value = result.user
      await fetchUserData()
      state.isAuthenticated = true
      if (routerInstance) {
        await routerInstance.push({ path: '/fitness-activity', force: true })
      }
    } catch (error) {
      toast.displayError('Invalid credentials')
    }
  }

  const signup = async (credentials: Credentials) => {
    loading.toggleLoading('auth-register-loading')
    try {
      const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
        email: credentials.email,
        password: credentials.password,
      })
      firebaseUser.value = result.user

      await authService.signup({
        id: result.user.uid,
        name: credentials.name,
        acknowledged_terms_and_conditions: credentials.acknowledged_terms_and_conditions,
      })

      toast.displaySuccess('Success! Please check your email to verify your account.')
      if (routerInstance) {
        await fetchUserData()
        await routerInstance.push({ path: '/onboarding' })
      }
      return true
    } catch (error) {
      toast.displayError(error)
      return error
    } finally {
      loading.toggleLoading('auth-register-loading')
    }
  }

  const verifyPin = async (payload: string) => {
    try {
      await authService.verifyPin(payload)
      toast.displaySuccess('Thank you, email verified!', 2500)
      return true
    } catch (error) {
      toast.displayError(error)
    }
  }

  const resendPin = async () => {
    try {
      await authService.resendPin()
      return true
    } catch (error) {
      toast.displayError(error)
    }
  }

  const logout = async () => {
    try {
      await FirebaseAuthentication.signOut()
      resetUserData()
      if (routerInstance) {
        await routerInstance.push({ path: '/login', force: true })
      }
    } catch (error) {
      toast.displayError(error)
    }
  }

  const fetchUserData = async () => {
    try {
      const response = await userService.me()
      state.authUser = response.data
      state.isAuthenticated = true
      profileStore.profile = response.data.profile
    } catch (error) {
      toast.displayError(error)
      state.isAuthenticated = false
    }
  }

  const resetUserData = () => {
    state.authUser = undefined
    state.isAuthenticated = false
    firebaseUser.value = null
  }

  const initAuth = async () => {
    if (isInitialized.value) {
      return
    }

    return new Promise<void>(async (resolve) => {
      const listenerHandle = await FirebaseAuthentication.addListener('authStateChange', async (change) => {
        if (isInitialized.value) {
          return
        }

        if (change.user) {
          firebaseUser.value = change.user
          if (!state.authUser) {
            await fetchUserData()
          }
        } else {
          resetUserData()
        }

        isInitialized.value = true
        await listenerHandle.remove()
        resolve()
      })

      // Fallback in case the listener doesn't fire
      setTimeout(async () => {
        if (!isInitialized.value) {
          isInitialized.value = true
          await listenerHandle.remove()
          resolve()
        }
      }, 5000) // 5 second timeout
    })
  }

  return {
    ...toRefs(state),
    firebaseUser,
    verifyPin,
    resendPin,
    profileUsername,
    setRouter,
    isInitialized,
    login,
    signup,
    logout,
    initAuth,
    fetchUserData,
  }
})
