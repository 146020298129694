import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyB9N_dInLU_IyRWSGmg94-g1j_ubx44wWk',
  authDomain: 'fullathlete-ca903.firebaseapp.com',
  projectId: 'fullathlete-ca903',
  storageBucket: 'fullathlete-ca903.appspot.com',
  messagingSenderId: '63574688380',
  appId: '1:63574688380:web:5877c1698a16e2b56ebff9',
  measurementId: 'G-1ZX4JJ7E09',
}

const app = initializeApp(firebaseConfig)

// Initialize Firebase services
const auth = getAuth(app)
const db = getFirestore(app)

export { app, auth, db }
