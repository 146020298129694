import type { ContactUs } from '@/types/ContactUs'
import * as httpClient from './capacitor-http-client'
import type { ForgotPassword } from '@/types/ForgotPassword'

const baseUrl = '/user'

const postContactUsForm = (payload: ContactUs) =>
  httpClient.post<ContactUs>(`${baseUrl}/contact-us`, payload).then((response) => response.data)

const postForgotPasswordForm = (payload: ForgotPassword) =>
  httpClient.post<ContactUs>(`${baseUrl}/reset-password`, payload).then((response) => response.data)

const me = () => httpClient.get(`${baseUrl}/me`).then((response) => response.data)

const fetchUserStats = (id: string) => httpClient.get(`${baseUrl}/${id}/stats`).then((response) => response.data)

const fetchPublicUser = (id: string) => httpClient.get(`${baseUrl}/${id}`).then((response) => response.data)

const fetchUserFeed = (id: string) => httpClient.get(`${baseUrl}/${id}/feed`).then((response) => response.data)

const userService = { postContactUsForm, postForgotPasswordForm, me, fetchPublicUser, fetchUserFeed, fetchUserStats }

export default userService
